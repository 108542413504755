.indicator-hidden{display:none !important}
.indicator-shown__calendar{position:relative !important;top:0 !important;left:5px !important;transform:none !important}
.indicator-shown__login{position:initial !important;top:0 !important;right:0 !important;transform:none !important;padding:0 80px !important;min-width:400px;height:240px !important}
.indicator-shown__login circle{stroke-width:1.8px !important}
.indicator-shown__table{position:initial !important;transform:none !important;margin-left:auto;margin-right:auto}
.indicator-shown__table circle{stroke-width:2px !important}
.indicator-shown__drawer{top:50% !important;left:50% !important;transform:translate(-50%, -50%) !important}
.react-table__row-wrapper{display:flex;flex-wrap:wrap;padding-bottom:20px}
.react-table .name{text-decoration:underline;cursor:pointer}
@media (max-width: 767px){.react-table__row-wrapper{display:flex;flex-direction:column;align-items:center}}
.react-table .ReactTable{font-size:16px}
.react-table .ReactTable .rt-thead .rt-th:first-child{align-items:center;display:flex;justify-content:center}
.react-table .ReactTable .rt-thead .rt-th:first-child .rt-resizable-header-content{position:relative;right:2px}
.react-table .ReactTable .rt-tbody .rt-td div>div>input{height:40px !important}
body{font-family:"Ubuntu", "sans-serif";margin:0;-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
#root{position:relative;height:100%;box-sizing:border-box}
#root>div{display:block;min-height:100vh;position:relative}
.anonymous-access{background-color:#FFFFFF;background-image:url(/static/media/bg.jpg);background-size:cover;background-repeat:no-repeat}
.anonymous-access.success-page{background-color:#FFFFFF;background-position-y:11%;background-image:url(/static/media/Group.png);background-size:contain;background-repeat:no-repeat;display:flex;align-content:center}
.anonymous-access .app__content{height:100%;width:100%;display:block}
.authored-access{background-color:rgba(225,232,241,0.29)}
.authored-access__instructor,.authored-access__admin{background-color:white}
.authored-access.calendar-page{background-color:#FFFFFF}
.app__sidebar,.app__content{display:inline-flex;vertical-align:top}
.app__sidebar{width:210px;height:100%;background-color:#FFFFFF;box-shadow:rgba(0,0,0,0.16) 0px 3px 10px,rgba(0,0,0,0.23) 0px 3px 10px;margin-top:-10px;padding-bottom:10px;position:fixed;z-index:1000;left:0px;-webkit-transform:perspective(1px) translate3d(0, 0, 0) !important;transform:perspective(1px) translate3d(0, 0, 0) !important}
.app__content{width:calc(100% - 210px);margin-left:210px}
.app__content>div{width:100%}
.notifications-wrapper{width:100%}
input:-webkit-autofill{-webkit-box-shadow:0 0 0 1000px white inset !important}
.portable-layout{padding:40px 10px;background:white;height:calc(100vh - 80px)}
.portable-layout .authored-access{overflow:auto}
.cookie-footer{z-index:10000000 !important;background-color:#1E88E5 !important;align-items:center !important}
.cookie-footer--content{font-size:13px}
.cookie-footer>button{color:#1E88E5 !important;background-color:white !important;font-family:"Ubuntu";border-radius:4px !important;cursor:pointer}
.cookie-footer>button:hover{transition:all 0.2s ease;color:white !important;background-color:#00559D !important}
._hj-f5b2a1eb-9b07_widget{z-index:50000 !important}
